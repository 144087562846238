/*
 * @Description: 顶栏用户模块
 * @Author: wudifu
 * @Date: 2021-04-19 10:56:26
 * @LastEditors: wudifu
 * @LastEditTime: 2021-09-07 15:06:45
 */
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Dropdown,
  Modal,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  ModalProps,
  Divider,
} from "antd";
import { MenuProps } from "antd/lib/menu";
import {
  DownOutlined,
  LeftOutlined,
  CheckCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/auth/slice";
import { userInfoSelector } from "../../store/auth/selector";

import {
  checkUser,
  getVerificationCode,
  verifyUser,
  resetPassword,
} from "../../api/auth/forget-password";

import { userReset } from "../../api/auth/auth";

import "./header-user.scss";
import usernameImg from "./assets/images/username.png";
import passwordImg from "./assets/images/password.png";

import { UserOutlined } from "@ant-design/icons";

enum propsToApiKey {
  "cellphone" = "phone",
  "email" = "mailbox",
}

export default function HeaderUser(props: {
  hasOtherMethods?: boolean;
  forgetPasswordOptions?: ("email" | "cellphone")[];
}) {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<
    | "updatePasswordByOld"
    | "verification"
    | "updatePasswordByVerification"
    | "updateSuccess"
  >("updatePasswordByOld");

  const onDropdownMenuClick: MenuProps["onClick"] = (item) => {
    switch (item.key) {
      case "updatePassword":
        setModalVisible(true);
        break;
      case "logout":
        handleLogout();
        break;
    }
  };

  const [updateByOldLoading, setUpdateByOldLoading] = useState(false);
  const onUpdateByOldFormFinished = async (form: {
    old: string;
    password: string;
  }) => {
    setUpdateByOldLoading(true);
    try {
      const status = await userReset({
        old: form.old,
        password: form.password,
      });
      console.log(status);
      setModalType("updateSuccess");
    } catch (err: any) {
      console.error(err);
      message.error(err.data);
    } finally {
      setUpdateByOldLoading(false);
    }
  };

  // 验证相关
  const [verificationType, setVerificationType] = useState<
    "cellphone" | "email"
  >(() => {
    if (props.forgetPasswordOptions) {
      if (props.forgetPasswordOptions.length === 1) {
        return props.forgetPasswordOptions[0];
      } else {
        return "cellphone";
      }
    } else {
      return "cellphone";
    }
  });
  const verificationTitle = useMemo(() => {
    return {
      cellphone: "手机",
      email: "邮箱",
    }[verificationType];
  }, [verificationType]);
  const [verificationData, setVerificationData] = useState<{
    username: string;
    phone: string;
    mailbox: string;
  } | null>(null);
  useEffect(() => {
    if (userInfo && modalVisible) {
      const func = async () => {
        const data = await checkUser(userInfo.username);
        setVerificationData(data as any);
      };
      func();
    }
  }, [userInfo, modalVisible]);

  const selectedVerificationData = useMemo(() => {
    return verificationData
      ? verificationData[propsToApiKey[verificationType]]
      : "";
  }, [verificationType, verificationData]);
  const verificationDisabledInterval = useRef<number>();
  const [verificationDisabledTime, setVerificationDisabledTime] = useState(60);
  const [verificationDisabled, setVerificationDisabled] = useState(false);
  useEffect(() => {
    if (verificationDisabled) {
      verificationDisabledInterval.current = window.setInterval(() => {
        if (verificationDisabledTime > 0) {
          setVerificationDisabledTime(verificationDisabledTime - 1);
        }
      }, 1000);
    } else {
      setVerificationDisabledTime(60);
    }
    return () => {
      window.clearInterval(verificationDisabledInterval.current);
    };
  }, [verificationDisabled, verificationDisabledTime]);
  useEffect(() => {
    if (modalType !== "verification" || verificationDisabledTime === 0) {
      if (verificationDisabledInterval) {
        window.clearInterval(verificationDisabledInterval.current);
        setVerificationDisabled(false);
      }
    }
  }, [modalType, verificationDisabledTime]);
  useEffect(() => {
    if (modalType !== "updatePasswordByVerification") {
      if (props.forgetPasswordOptions) {
        if (props.forgetPasswordOptions.length === 1) {
          setVerificationType(props.forgetPasswordOptions[0]);
        } else {
          setVerificationType("cellphone");
        }
      } else {
        setVerificationType("cellphone");
      }
    }
  }, [modalType, props.forgetPasswordOptions]);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const onVerificationFormFinished = async (form: { code: string }) => {
    setVerificationLoading(true);
    try {
      const status = await verifyUser(form.code);
      console.log(status);
      setModalType("updatePasswordByVerification");
    } catch (err: any) {
      message.error(err.data);
    } finally {
      setVerificationLoading(false);
    }
  };

  const [updateByVerificationLoading, setUpdateByVerificationLoading] =
    useState(false);
  const onUpdateByVerificationFormFinished = async (form: {
    password: string;
  }) => {
    setUpdateByVerificationLoading(true);
    try {
      const status = await resetPassword(form.password);
      console.log(status);
      setModalType("updateSuccess");
    } catch (err: any) {
      message.error(err.data);
    } finally {
      setUpdateByVerificationLoading(false);
    }
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const closeModalFunc = useCallback(() => {
    setModalVisible(false);
    setModalType("updatePasswordByOld");
  }, []);

  const onModalCancel: ModalProps["onCancel"] = () => {
    closeModalFunc();
  };

  const modalClosable = useMemo(() => {
    switch (modalType) {
      case "updateSuccess":
        return false;
      default:
        return true;
    }
  }, [modalType]);

  const modalTitle = useMemo(() => {
    switch (modalType) {
      case "updatePasswordByOld":
      case "updatePasswordByVerification":
        return (
          <div className="components-header-user__update-password__modal__title">
            修改密码
          </div>
        );
      case "verification":
        return (
          <div
            className="components-header-user__verification__modal__title"
            onClick={() => {
              setModalType("updatePasswordByOld");
            }}
          >
            <LeftOutlined />
            返回
          </div>
        );
      default:
        return null;
    }
  }, [modalType]);

  const modalBody = useMemo(() => {
    if (userInfo) {
      switch (modalType) {
        case "updatePasswordByOld":
          return (
            <div className="components-header-user__update-by-old">
              <Form
                size="large"
                labelCol={{ span: 5 }}
                onFinish={onUpdateByOldFormFinished}
              >
                <Form.Item>
                  <Input
                    prefix={<img src={usernameImg} alt="username-img" />}
                    disabled
                    value={userInfo.username}
                  />
                </Form.Item>
                <Form.Item
                  name="old"
                  rules={[
                    { required: true, message: "请输入旧密码！" },
                    { min: 6, message: "密码不得少于6位！" },
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passwordImg} alt="password-img" />}
                    placeholder="请输入旧密码"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "请输入新密码！" },
                    { min: 6, message: "密码不得少于6位！" },
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passwordImg} alt="password-img" />}
                    placeholder="请输入新密码"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  rules={[
                    { required: true, message: "请再次输入新密码！" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入的密码不一致");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passwordImg} alt="password-img" />}
                    placeholder="请再次输入新密码"
                  />
                </Form.Item>
                <Divider />
                <div className="components-header-user__update-by-old__footer">
                  {props.hasOtherMethods !== false ? (
                    <div
                      className="components-header-user__update-by-old__footer__verification-by-other-button"
                      onClick={() => {
                        setModalType("verification");
                      }}
                    >
                      其他方式验证？
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="components-header-user__update-by-old__footer__button-group">
                    <Button
                      type="primary"
                      loading={updateByOldLoading}
                      htmlType="submit"
                      size="middle"
                    >
                      完成
                    </Button>
                    <Button
                      size="middle"
                      onClick={() => {
                        closeModalFunc();
                      }}
                    >
                      取消
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          );
        case "verification":
          return (
            <div className="components-header-user__verification">
              <div className="components-header-user__verification__warning-line">
                <ExclamationCircleFilled className="components-header-user__verification__warning-line__warning-icon" />
                正在对用户
                <span className="components-header-user__verification__warning-line__user">
                  {userInfo.username}
                </span>
                进行修改密码操作
              </div>
              <div className="components-header-user__verification__button">
                {(!props.forgetPasswordOptions ||
                  props.forgetPasswordOptions.length === 0 ||
                  props.forgetPasswordOptions.findIndex((item) => {
                    return item === "cellphone";
                  }) !== -1) && (
                  <div
                    className={`components-header-user__verification__button__verification-type ${
                      verificationType === "cellphone"
                        ? "components-header-user__verification__button__verification-type--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setVerificationType("cellphone");
                    }}
                  >
                    手机验证
                  </div>
                )}
                {(!props.forgetPasswordOptions ||
                  props.forgetPasswordOptions.length === 0 ||
                  props.forgetPasswordOptions.findIndex((item) => {
                    return item === "email";
                  }) !== -1) && (
                  <div
                    className={`components-header-user__verification__button__verification-type ${
                      verificationType === "email"
                        ? "components-header-user__verification__button__verification-type--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setVerificationType("email");
                    }}
                  >
                    邮箱验证
                  </div>
                )}
              </div>
              <Form size="large" onFinish={onVerificationFormFinished}>
                <Row gutter={20}>
                  <Col flex="auto">
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: "请输入验证码！" },
                        { len: 6, message: "验证码为6位数" },
                      ]}
                    >
                      <Input placeholder="请输入6位数验证码" />
                    </Form.Item>
                  </Col>
                  <Col flex="1">
                    <Button
                      className="components-header-user__verification__code-button"
                      disabled={verificationDisabled}
                      onClick={() => {
                        getVerificationCode(
                          { cellphone: "1", email: "2" }[
                            verificationType
                          ] as any
                        )
                          .then((res) => {
                            console.log(res);
                            message.success("验证码已发送");
                            setVerificationDisabled(true);
                          })
                          .catch((err) => console.error(err));
                      }}
                    >
                      获取验证码
                    </Button>
                  </Col>
                  <Col flex="1">
                    <div className="components-header-user__verification__code-disabled-text">
                      {verificationDisabled && `${verificationDisabledTime}s`}
                    </div>
                  </Col>
                </Row>
                <div className="components-header-user__verification__text">
                  点击获取验证码，验证码将发送至{verificationTitle}{" "}
                  {selectedVerificationData}
                </div>
                <div className="components-header-user__verification__next-button-row">
                  <Button
                    className="components-header-user__verification__next-button-row__next-button"
                    type="primary"
                    loading={verificationLoading}
                    htmlType="submit"
                  >
                    下一步
                  </Button>
                </div>
              </Form>
            </div>
          );
        case "updatePasswordByVerification":
          return (
            <div className="components-header-user__update-by-verification">
              <Form
                size="large"
                labelCol={{ span: 5 }}
                onFinish={onUpdateByVerificationFormFinished}
              >
                <Form.Item>
                  <Input
                    prefix={<img src={usernameImg} alt="username-img" />}
                    disabled
                    value={userInfo.username}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "请输入新密码！" },
                    { min: 6, message: "密码不得少于6位！" },
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passwordImg} alt="password-img" />}
                    placeholder="请输入新密码"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  rules={[
                    { required: true, message: "请再次输入新密码！" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入的密码不一致");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passwordImg} alt="password-img" />}
                    placeholder="请再次输入新密码"
                  />
                </Form.Item>
                <Divider />
                <div className="components-header-user__update-by-verification__footer">
                  <Button
                    type="primary"
                    loading={updateByVerificationLoading}
                    htmlType="submit"
                    size="middle"
                  >
                    完成
                  </Button>
                  <Button
                    size="middle"
                    onClick={() => {
                      closeModalFunc();
                    }}
                  >
                    取消
                  </Button>
                </div>
              </Form>
            </div>
          );
        case "updateSuccess":
          return (
            <div className="components-header-user__update-success">
              <CheckCircleOutlined className="components-header-user__update-success__icon" />
              <div className="components-header-user__update-success__text">
                密码修改成功
              </div>
              <Button
                className="logout-button"
                type="primary"
                onClick={handleLogout}
              >
                重新登录
              </Button>
            </div>
          );
      }
    } else return "未登录";
  }, [
    modalType,
    userInfo,
    handleLogout,
    closeModalFunc,
    props.forgetPasswordOptions,
    props.hasOtherMethods,
    selectedVerificationData,
    updateByOldLoading,
    updateByVerificationLoading,
    verificationDisabled,
    verificationLoading,
    verificationDisabledTime,
    verificationTitle,
    verificationType,
  ]);

  return (
    <div className="components-header-user">
      <UserOutlined className="components-header-user__user-icon" />
      <Dropdown
        menu={{
          items: [
            { label: "修改密码", key: "updatePassword" },
            { label: "退出登录", key: "logout" },
          ],
          onClick: onDropdownMenuClick,
        }}
      >
        <div className="components-header-user__dropdown-trigger">
          <div>{userInfo?.username || "未登录"}</div>
          <DownOutlined className="components-header-user__down-icon" />
        </div>
      </Dropdown>
      <Modal
        open={modalVisible}
        title={modalTitle}
        closable={modalClosable}
        maskClosable={false}
        onCancel={onModalCancel}
        footer={null}
        destroyOnClose
        getContainer={false}
      >
        {modalBody}
      </Modal>
    </div>
  );
}
