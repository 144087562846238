/*
 * @Description: 时间轴配置文件
 * @Author: wudifu
 * @Date: 2021-07-28 15:01:26
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 11:47:45
 */

/**
 * 时间轴数据类型
 * 二维数组
 * 第一层为按钮数据的分类
 * 第二层为按钮数据
 */
export type TimelineDataType = {
  type: string;
  data: { value: string; label: string }[];
}[];

/**
 * 获取当前选中按钮在时间轴数据中的下标元组
 * @param selected 当前选中按钮值
 * @param arr 时间轴数据
 * @returns 选中按钮的下标元组，第一个为第一层类型的下标，第二个为第二层数据的下标
 */
function findSelectedIndex(
  selected: string,
  arr: TimelineDataType
): [number, number] {
  return arr.reduce(
    (indexTuple, type, typeIndex) => {
      // 在当前分类下，查找选中按钮是否存在，存在则返回下标，不存在返回-1
      const buttonIndex = type.data.findIndex((button, buttonIndex) => {
        return button.value === selected;
      });
      // 若选中按钮属于当前分类，则将分类下标与按钮下标都设置在元组中
      if (buttonIndex !== -1) {
        indexTuple[0] = typeIndex;
        indexTuple[1] = buttonIndex;
      }
      return indexTuple;
    },
    // 默认下标不存在，都为-1
    [-1, -1]
  );
}

/**
 * 获取当前选中按钮前一个按钮的值
 * @param selectedValue 选中按钮值
 * @param data 时间轴数据
 * @returns 选中按钮前一个的值
 */
function backwardOne(selectedValue: string, data: TimelineDataType): string {
  // 当前选中按钮下标的元组
  const currentIndexTuple = findSelectedIndex(selectedValue, data);
  // 若当前选中按钮是当前分类下数据中的第一个，则判断当前分类是否为数据中的第一个
  if (currentIndexTuple[1] === 0) {
    // 若分类是数据中的第一个，则返回最后一个分类的最后一个按钮值
    if (currentIndexTuple[0] === 0) {
      return data[data.length - 1].data[data[data.length - 1].data.length - 1]
        .value;
    }
    // 若分类不是数据中的第一个，则返回上一个分类的最后一个按钮值
    else {
      const currentTypeData = data[currentIndexTuple[0] - 1].data;
      return currentTypeData[currentTypeData.length - 1].value;
    }
  }
  // 若当前选中按钮不是当前分类下数据中的第一个，则返回前一个按钮的值
  else {
    return data[currentIndexTuple[0]].data[currentIndexTuple[1] - 1].value;
  }
}

/**
 * 获取当前选中按钮后一个按钮的值
 * @param selectedValue 选中按钮值
 * @param data 时间轴数据
 * @returns 选中按钮后一个的值
 */
function forwardOne(selectedValue: string, data: TimelineDataType): string {
  // 当前选中按钮下标的元组
  const currentIndexTuple = findSelectedIndex(selectedValue, data);
  // 若当前选中按钮是当前分类下数据中的最后一个，则判断当前分类是否为数据中的最后一个
  if (currentIndexTuple[1] === data[currentIndexTuple[0]].data.length - 1) {
    // 若分类是数据中的最后一个，则返回第一个分类的第一个按钮值
    if (currentIndexTuple[0] === data.length - 1) {
      return data[0].data[0].value;
    }
    // 若分类不是数据中的最后一个，则返回下一个分类的第一个按钮值
    else {
      const currentTypeData = data[currentIndexTuple[0] + 1].data;
      return currentTypeData[0].value;
    }
  }
  // 若当前选中按钮不是当前分类下数据中的最后一个，则返回后一个按钮的值
  else {
    return data[currentIndexTuple[0]].data[currentIndexTuple[1] + 1].value;
  }
}

/**
 * 向前或向后选中一个按钮
 * @param selectedValue 选中按钮值
 * @param data 时间轴数据
 * @param type 操作的类型，向前或向后
 * @returns 向前或向后选中按钮的值
 */
export function backwardOrForwardOne(
  selectedValue: string,
  data: TimelineDataType,
  type: "backward" | "forward"
): string {
  if (type === "backward") {
    return backwardOne(selectedValue, data);
  } else {
    return forwardOne(selectedValue, data);
  }
}
