/*
 * @Description: auth selector
 * @Author: wudifu
 * @Date: 2021-01-14 14:56:19
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-13 17:40:59
 */
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers";

// 使用reselect创建selector
export const userInfoSelector = createSelector(
  (state: RootState) => state.authReducer.userInfo,
  (userInfo) => userInfo
);
export const loginLoadingSelector = createSelector(
  (state: RootState) => state.authReducer.loginLoading,
  (loginLoading) => loginLoading
);
export const pageTypeSelector = createSelector(
  (state: RootState) => state.authReducer.pageType,
  (pageType) => pageType
);
export const registerLoadingSelector = createSelector(
  (state: RootState) => state.authReducer.registerLoading,
  (registerLoading) => registerLoading
);
export const authSelector = createSelector(
  userInfoSelector,
  loginLoadingSelector,
  (userInfo, loginLoading) => ({ userInfo, loginLoading })
);
