/*
 * @Description: 向前、向后按钮
 * @Author: wudifu
 * @Date: 2021-12-06 10:49:14
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 19:43:10
 */
import { useMemo } from "react";

import "./button-forward-backward.scss";

export default function ButtonForwardBackward(props: {
  type: "forward" | "backward";
  className?: string;
  onClick?: () => void;
}) {
  // 类名计算属性
  const computedClassName = useMemo(() => {
    switch (props.type) {
      case "forward":
        return `components-button-forward-backward__button--forward`;
      case "backward":
        return `components-button-forward-backward__button--backward`;
    }
  }, [props.type]);
  return (
    <div
      className={`components-button-forward-backward ${computedClassName} ${
        props.className ? props.className : ""
      }`}
      onClick={props.onClick}
    >
      {/* 多套一层是因为clipPath后，可点击区域也被裁掉了，故外层用来点击，内层裁剪用于样式 */}
      <div
        className={`components-button-forward-backward__button ${computedClassName}`}
      ></div>
    </div>
  );
}
