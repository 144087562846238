/*
 * @Description: 可滚动时间轴
 * @Author: wudifu
 * @Date: 2021-12-06 10:31:05
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 19:44:14
 */
import { useState, useRef, useEffect, useCallback } from "react";
import ButtonForwardBackward from "./components/button-forward-backward";
import ButtonPlayPause from "./components/button-play-pause";
import ButtonSpeed from "./components/button-speed";
import ButtonsTypedScrolled from "./components/buttons-typed-scrolled";
import { backwardOrForwardOne, TimelineDataType } from "./utils";
import "./index.scss";
export default function TimelineScrolled(props: {
  data: TimelineDataType;
  title?: string;
  className?: string;
  value?: string;
  onTimelineChange?: (value: string) => void;
}) {
  const { data, title, className, value, onTimelineChange } = props;

  // 数据Ref
  const dataRef = useRef<TimelineDataType>(data);
  // 数据变更时，更新数据Ref
  useEffect(() => {
    dataRef.current = data;
    setSelectedButton(data[0].data[0].value);
  }, [data]);

  // 当前选中时间按钮
  const [selectedButton, setSelectedButton] = useState<string>(
    data.length > 0 ? data[0].data[0].value : ""
  );
  // 选中时间按钮的ref
  const selectedButtonRef = useRef<string>(
    data.length > 0 ? data[0].data[0].value : ""
  );
  // 选中时间改变时，更新选中时间的ref
  useEffect(() => {
    selectedButtonRef.current = selectedButton;
  }, [selectedButton]);
  // 监听选中按钮值变化，向外传递事件
  useEffect(() => {
    onTimelineChange && onTimelineChange(selectedButton);
  }, [selectedButton, onTimelineChange]);

  // 播放与暂停状态
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // 播放计时器
  const playInterval = useRef<number | null>(null);

  // 播放速度按钮与事件
  const [speed, setSpeed] = useState<number>(1);
  const onSpeedChange = useCallback((value: number) => {
    setSpeed(value);
  }, []);

  // 监听播放状态与播放速度变化，生成或删除计时器
  useEffect(() => {
    // 播放状态为true，生成计时器
    if (isPlaying) {
      // 生成计时器时，先将之前的清除
      if (playInterval.current) window.clearInterval(playInterval.current);
      playInterval.current = window.setInterval(() => {
        setSelectedButton(
          backwardOrForwardOne(
            selectedButtonRef.current,
            dataRef.current,
            "forward"
          )
        );
      }, 1000 / speed);
    }
    // 播放状态为false清除计时器
    else {
      if (playInterval.current) window.clearInterval(playInterval.current);
      playInterval.current = null;
    }
  }, [isPlaying, speed]);

  // 向前选中一个按钮，点选按钮将暂停播放
  const onBackwardClick = useCallback(() => {
    setIsPlaying(false);
    setSelectedButton(
      backwardOrForwardOne(
        selectedButtonRef.current,
        dataRef.current,
        "backward"
      )
    );
  }, []);

  // 向后选中一个按钮，点选按钮将暂停播放
  const onForwardClick = useCallback(() => {
    setIsPlaying(false);
    setSelectedButton(
      backwardOrForwardOne(
        selectedButtonRef.current,
        dataRef.current,
        "forward"
      )
    );
  }, []);

  // 页面卸载时，停止计时器
  useEffect(() => {
    return () => {
      if (playInterval.current) window.clearInterval(playInterval.current);
    };
  }, []);
  return data.length > 0 ? (
    <div className={`components-timeline-scrolled ${className}`}>
      <div className="components-timeline-scrolled__panel-area">
        {title && (
          <div className="components-timeline-scrolled__panel-area__title">
            {title}
          </div>
        )}
        <div className="components-timeline-scrolled__panel-area__buttons">
          <ButtonForwardBackward type="backward" onClick={onBackwardClick} />
          <ButtonPlayPause
            isPlaying={isPlaying}
            onIsPlayingChange={(isPlaying) => {
              setIsPlaying(isPlaying);
            }}
          />
          <ButtonForwardBackward type="forward" onClick={onForwardClick} />
          <ButtonSpeed onSpeedChange={onSpeedChange} />
        </div>
      </div>
      <ButtonsTypedScrolled
        data={data}
        selectedButton={value || selectedButton}
        onSelectedButtonChange={(value) => {
          setSelectedButton(value);
          setIsPlaying(false);
        }}
      />
    </div>
  ) : null;
}
