/*
 * @Description: 可滚动时间轴示例页
 * @Author: wudifu
 * @Date: 2021-12-06 10:31:53
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 21:04:09
 */
import { useState } from "react";
import { Radio, Input, Card } from "antd";
import dayjs from "dayjs";
import TimelineScrolled from "../../components/timeline-scrolled";

import "./index.scss";

let tempData: { label: string; value: string }[] = [];
for (let i = 0; i < 240; i++) {
  const tempDatetime = dayjs().add(i, "hour");
  tempData.push({
    label: tempDatetime.format("YYYY-MM-DD HH"),
    value: tempDatetime.format("YYYYMMDDHH"),
  });
}
let moreData: { type: string; data: { label: string; value: string }[] }[] =
  tempData.reduce<{ type: string; data: { label: string; value: string }[] }[]>(
    (arr, item) => {
      const arrIndex = arr.findIndex((arrItem) => {
        return arrItem.type === item.label.slice(0, 10);
      });
      if (arrIndex !== -1) {
        arr[arrIndex].data.push({
          label: item.label.slice(11) + "时",
          value: item.value,
        });
      } else {
        arr.push({
          type: item.label.slice(0, 10),
          data: [
            {
              label: item.label.slice(11) + "时",
              value: item.value,
            },
          ],
        });
      }
      return arr;
    },
    []
  );

const data: { [index: string]: any } = {
  less: [
    {
      type: "",
      data: [
        {
          label: "11-29",
          value: "20211129",
        },
        {
          label: "11-30",
          value: "20211130",
        },
        {
          label: "12-01",
          value: "20211201",
        },
        {
          label: "12-02",
          value: "20211202",
        },
      ],
    },
  ],
  more: moreData,
};

export default function TimelineScrolledPage() {
  const [selectedRadio, setSelectedRadio] = useState<string>("less");
  const [selectedDatetime, setSelectedDatetime] = useState<string>("20211129");
  const [timelineTitle, setTimelineTitle] = useState<string>("");
  return (
    <div className="timeline-scrolled-page">
      <Card className="timeline-demo">
        <div className="action-row">
          <Input
            className="input"
            placeholder="时间轴标题"
            value={timelineTitle}
            onChange={(event) => {
              setTimelineTitle(event.target.value);
            }}
          />
          <Radio.Group
            options={[
              { label: "无分类的少量数据", value: "less" },
              {
                label: "有分类的大量数据",
                value: "more",
              },
            ]}
            value={selectedRadio}
            onChange={(event) => {
              setSelectedRadio(event.target.value);
            }}
          ></Radio.Group>
          <div className="selected-datetime">
            当前选中时间：{selectedDatetime}
          </div>
        </div>
        <div className="timeline-wrapper">
          <TimelineScrolled
            className="timeline"
            title={timelineTitle}
            data={data[selectedRadio]}
            value={selectedDatetime}
            onTimelineChange={(value) => {
              setSelectedDatetime(value);
            }}
          />
        </div>
      </Card>
      <zero-md
        class="document-timeline"
        src={process.env.PUBLIC_URL + "/READMETIMELINE.md"}
      ></zero-md>
    </div>
  );
}
