/*
 * @Description: 404页面
 * @Author: wudifu
 * @Date: 2021-04-28 11:19:21
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-28 11:20:21
 */

import { Result } from "antd";

export default function Error404() {
  return (
    <Result status="404" title="404" subTitle="抱歉，您访问的页面不存在！" />
  );
}
