/*
 * @Description: 二维码接口
 * @Author: wudifu
 * @Date: 2021-01-20 17:30:14
 * @LastEditors: wudifu
 * @LastEditTime: 2021-05-28 11:49:31
 */

import { drawCode } from "../../components/login/components/captcha/utils";

export const getCaptcha = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const obj = drawCode();
      obj ? resolve(obj.base64) : reject(new Error("生成失败"));
    }, 500);
  });
