/*
 * @Description: 步骤按钮
 * @Author: wudifu
 * @Date: 2021-01-22 13:32:45
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 21:26:18
 */
import React from "react";
import { useDispatch } from "react-redux";
import { setPageType } from "../../../../../store/auth/slice";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "./main-step-button.scss";
export default function MainStepButton(props: { loading: boolean }) {
  const dispatch = useDispatch();
  return (
    <div className="components-main-step-button">
      <Button type="primary" loading={props.loading} htmlType="submit">
        下一步
      </Button>
      <div className="components-main-step-button__back-to-login">
        <LeftOutlined />
        <span>已有账号？</span>
        <span
          className="components-main-step-button__back-to-login__login-span"
          onClick={() => {
            dispatch(setPageType("login"));
          }}
        >
          登录
        </span>
      </div>
    </div>
  );
}
