/*
 * @Description: auth slice
 * @Author: wudifu
 * @Date: 2021-01-12 10:23:18
 * @LastEditors: wudifu
 * @LastEditTime: 2021-09-07 15:07:46
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userLogin, identityRegister } from "../../api/auth/auth";
import { message } from "antd";
import { AppDispatch } from "..";

// 使用redux-toolkit创建slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : null,
    loginLoading: false,
    pageType: "login",
    registerLoading: false,
    isInLogout: false,
  },
  reducers: {
    setUserInfo(state, action: PayloadAction<any>) {
      state.userInfo = action.payload;
    },
    setLoginLoading(state, action: PayloadAction<boolean>) {
      state.loginLoading = action.payload;
    },
    setIsInLogout(state, action: PayloadAction<boolean>) {
      state.isInLogout = action.payload;
    },
    logout(state, action: PayloadAction<undefined>) {
      localStorage.removeItem("userInfo");
      state.userInfo = null;
    },
    loginExpired(state, action: PayloadAction<undefined>) {
      if (!state.isInLogout) {
        state.isInLogout = true;
        message.error("登录过期，请重新登录!");
        localStorage.removeItem("userInfo");
        state.userInfo = null;
      }
    },
    setPageType(state, action: PayloadAction<"login" | "register" | "forget">) {
      state.pageType = action.payload;
    },
    setRegisterLoading(state, action: PayloadAction<boolean>) {
      state.loginLoading = action.payload;
    },
  },
});

export const loginAsync =
  (data: { username: string; password: string }) => async (dispatch: any) => {
    dispatch(setLoginLoading(true));
    try {
      const userInfo = await userLogin(data);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      dispatch(setUserInfo(userInfo));
      message.success("登录成功");
      dispatch(setIsInLogout(false));
    } catch (err: any) {
      console.error(err);
      err.data ? message.error(err.data) : message.error("登录失败");
    } finally {
      dispatch(setLoginLoading(false));
    }
  };

export const registerAsync =
  (data: {
    username: string;
    password: string;
    phone: string;
    mailbox: string;
    roldId?: string;
    departmentId?: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch(setRegisterLoading(true));
    try {
      const userInfo = await identityRegister(data);
      console.log(userInfo);
      message.success("注册成功");
      dispatch(setPageType("login"));
      dispatch(setUserInfo(userInfo));
    } catch (err: any) {
      console.error(err);
      err.data ? message.error(err.data) : message.error("注册失败");
    } finally {
      dispatch(setRegisterLoading(false));
    }
  };

export const {
  setUserInfo,
  setLoginLoading,
  setIsInLogout,
  logout,
  loginExpired,
  setPageType,
  setRegisterLoading,
} = authSlice.actions;

export default authSlice.reducer;
