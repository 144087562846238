/*
 * @Description: 二维码组件
 * @Author: wudifu
 * @Date: 2021-01-18 19:49:29
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 21:24:28
 */
import { useRef, useState, useCallback, useEffect } from "react";
import { Spin } from "antd";
import { getCaptcha } from "../../../../api/auth/captcha";
import "./captcha.scss";

export default function Captcha() {
  const imgRef = useRef<HTMLImageElement>(null);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState("");
  const getNewCaptcha = useCallback(async () => {
    setCaptchaLoading(true);
    try {
      const img: any = await getCaptcha();
      setCaptchaImg(img);
    } catch (err) {
      console.error(err);
    } finally {
      setCaptchaLoading(false);
    }
  }, []);
  useEffect(() => {
    getNewCaptcha();
  }, [getNewCaptcha]);
  return (
    <div className="components-captcha" onClick={getNewCaptcha}>
      <Spin spinning={captchaLoading} delay={500}>
        <img ref={imgRef} src={captchaImg} alt="验证码"></img>
      </Spin>
    </div>
  );
}
