/*
 * @Description: 播放暂停按钮
 * @Author: wudifu
 * @Date: 2021-12-06 11:18:38
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 19:43:08
 */
import { useMemo } from "react";
import "./button-play-pause.scss";
export default function ButtonPlayPause(props: {
  isPlaying: boolean;
  onIsPlayingChange: (isPlaying: boolean) => void;
  className?: string;
}) {
  const computedClassName = useMemo(() => {
    if (props.isPlaying) {
      return "components-button-play-pause__button--play";
    } else {
      return "components-button-play-pause__button--pause";
    }
  }, [props.isPlaying]);
  return (
    <div
      className={`components-button-play-pause ${
        props.className ? props.className : ""
      }`}
      onClick={() => {
        props.onIsPlayingChange(!props.isPlaying);
      }}
    >
      {/* 多套一层是因为clipPath后，可点击区域也被裁掉了，故外层用来点击，内层裁剪用于样式 */}
      <div
        className={`components-button-play-pause__button ${computedClassName}`}
      ></div>
    </div>
  );
}
