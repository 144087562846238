/*
 * @Description: 注册表单
 * @Author: wudifu
 * @Date: 2021-01-18 17:42:14
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 22:09:45
 */
import { useSelector } from "react-redux";
import { setPageType, registerAsync } from "../../../../store/auth/slice";
import { registerLoadingSelector } from "../../../../store/auth/selector";

import { Form, Input, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Captcha from "../captcha";

import "./register-form.scss";
import usernameImg from "../../assets/images/username.png";
import passwordImg from "../../assets/images/password.png";
import phoneImg from "../../assets/images/phone.png";
import emailImg from "../../assets/images/email.png";

import { useAppDispatch } from "../../../../hooks";

export default function Register(props: { className?: string }) {
  const dispatch = useAppDispatch();
  const registerLoading = useSelector(registerLoadingSelector);
  const onFormFinished = (registerForm: {
    username: string;
    password: string;
    phone: string;
    mailbox: string;
    code: string;
  }) => {
    dispatch(registerAsync(registerForm));
  };
  return (
    <div className={props.className}>
      <div className="components-register__title">注册账号</div>
      <div className="components-register__divider" />
      <div className="components-register__back-to-login">
        <LeftOutlined />
        <span>已有账号？</span>
        <span
          className="components-register__back-to-login__login-span"
          onClick={() => {
            dispatch(setPageType("login"));
          }}
        >
          登录
        </span>
      </div>
      <Form
        className="components-register__form"
        name="register"
        size="large"
        onFinish={onFormFinished}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "请设置用户名！" }]}
        >
          <Input
            prefix={<img src={usernameImg} alt="username-img" />}
            placeholder="请设置用户名"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "请输入手机号！" },
            { pattern: /^\d{11}$/, message: "请输入11位数字！" },
          ]}
        >
          <Input
            prefix={<img src={phoneImg} alt="username-img" />}
            placeholder="请输入手机号"
          />
        </Form.Item>
        <Form.Item
          name="mailbox"
          rules={[
            { required: true, message: "请输入邮箱！" },
            { type: "email", message: "请输入正确的邮箱格式！" },
          ]}
        >
          <Input
            prefix={<img src={emailImg} alt="username-img" />}
            placeholder="请输入邮箱"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "请设置登录密码！" },
            { min: 6, message: "密码不得少于6位" },
          ]}
        >
          <Input.Password
            prefix={<img src={passwordImg} alt="username-img" />}
            placeholder="请设置登录密码"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: "请输入验证码！",
            },
          ]}
        >
          <Input addonAfter={<Captcha></Captcha>} placeholder="请输入验证码" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={registerLoading}
          block
        >
          注册
        </Button>
      </Form>
    </div>
  );
}
