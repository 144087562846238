/*
 * @Description: 登录组件
 * @Author: wudifu
 * @Date: 2021-01-11 13:27:39
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 23:02:55
 */
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { pageTypeSelector } from "../../store/auth/selector";
import LoginForm from "./components/login-form";
import RegisterForm from "./components/register-form";
import ForgetPassword from "./components/forget-password";

import "./login.scss";

export default function Login(props: {
  className?: string;
  title: string;
  logo?: ReactNode;
  logoTitleImg?: ReactNode;
  type?: "modal" | "drawer";
  theme?: "dark" | "light";
  loginFormOptions?: {
    hasRememberMe: boolean;
    hasRegister: boolean;
    hasForgetPassword: boolean;
  };
  forgetPasswordOptions?: ("email" | "cellphone")[];
}) {
  const pageType = useSelector(pageTypeSelector);
  return (
    <div className={`components-login ${props.className || ""}`}>
      {pageType !== "forget" ? (
        <div
          className={`components-login-${
            props.type === "drawer" ? props.type : "modal"
          }-${props.theme === "light" ? props.theme : "dark"}`}
        >
          <div className="components-login__background" />
          <div className="components-login__header">
            {props.logoTitleImg || (
              <>
                {props.logo || (
                  <div className="components-login__header__logo">
                    <span className="components-login__header__logo__text">
                      logo
                    </span>
                  </div>
                )}
                <div className="components-login__header__title">
                  {props.title}
                </div>
              </>
            )}
          </div>
          {pageType === "login" && (
            <LoginForm
              className={`${
                props.theme === "light"
                  ? "components-login-form-light"
                  : "components-login-form-dark"
              }`}
              {...props.loginFormOptions}
            ></LoginForm>
          )}
          {pageType === "register" && (
            <RegisterForm
              className={`${
                props.theme === "light"
                  ? "components-register-form-light"
                  : "components-register-form-dark"
              }`}
            ></RegisterForm>
          )}
        </div>
      ) : (
        <ForgetPassword
          title={props.title}
          logo={props.logo}
          forgetPasswordOptions={props.forgetPasswordOptions}
        ></ForgetPassword>
      )}
    </div>
  );
}
