/*
 * @Description: 403页面
 * @Author: wudifu
 * @Date: 2021-04-28 17:11:02
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 15:58:02
 */

import { Result } from "antd";

export default function Error403() {
  return (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，您没有访问该页面的权限！"
    />
  );
}
