/*
 * @Description: 登录服务接口
 * @Author: wudifu
 * @Date: 2021-01-07 17:48:13
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-21 19:34:13
 */

// 用户登录
export const userLogin = (data: { username: string; password: string }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ username: "admin", functions: [] });
    }, 1000);
  });

// 用户
export const identityRegister = (data: {
  username: string;
  password: string;
  phone: string;
  mailbox: string;
  roldId?: string;
  departmentId?: string;
}) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ username: 'admin', functions: [] });
    }, 1000);
  });

export const userReset = (data: { old: string; password: string }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("密码重置成功");
    }, 500);
  });
