/*
 * @Description: 忘记密码接口
 * @Author: wudifu
 * @Date: 2021-01-22 16:37:00
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-13 10:49:44
 */

export const checkUser = (info: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("用户校验成功");
    }, 500);
  });
};
export const getVerificationCode = (type: "1" | "2") => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("验证码发送成功");
    }, 500);
  });
};
export const verifyUser = (code: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("验证码验证成功");
    }, 500);
  });
};
export const resetPassword = (password: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("密码重置成功");
    }, 500);
  });
};
