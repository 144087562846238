/*
 * @Description: 播放速度按钮
 * @Author: wudifu
 * @Date: 2021-12-06 14:40:43
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 20:02:32
 */
import { useState } from "react";
import { Radio } from "antd";

import "./button-speed.scss";

export default function ButtonSpeed(props: {
  onSpeedChange: (speed: number) => void;
}) {
  const [selectedSpeed, setSelectedSpeed] = useState<number>(1);
  return (
    <Radio.Group
      className="components-button-speed"
      options={[
        {
          label: "慢",
          value: 0.5,
        },
        {
          label: "中",
          value: 1,
        },
        {
          label: "快",
          value: 2,
        },
      ]}
      optionType="button"
      buttonStyle="solid"
      value={selectedSpeed}
      onChange={(event) => {
        setSelectedSpeed(event.target.value);
        props.onSpeedChange(event.target.value);
      }}
    ></Radio.Group>
  );
}
