/*
 * @Description: 路由组件
 * @Author: wudifu
 * @Date: 2021-04-28 10:45:57
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 15:39:06
 */

import { useEffect } from "react";
import {
  useNavigate,
  useLocation,
  useRoutes,
  matchRoutes,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userInfoSelector } from "../store/auth/selector";
import { logout } from "../store/auth/slice";

import { routesConfig, frontPermissionedKey } from "./config";

export default function RenderRoute() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  /**
   * 记住登录状态时，关闭页面不退出登录
   */
  useEffect(() => {
    const beforeunloadFunc = (e: Event) => {
      const rememberMeJSON = localStorage.getItem("rememberMe");
      const rememberMe = rememberMeJSON ? JSON.parse(rememberMeJSON) : true;
      if (!rememberMe) dispatch(logout());
    };
    window.addEventListener("beforeunload", beforeunloadFunc);
    return () => {
      window.removeEventListener("beforeunload", beforeunloadFunc);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * 根据用户信息判断登录状态
     */
    if (userInfo) {
      if (location.pathname === "/login") return navigate("/");
    } else {
      if (location.pathname !== "/login") return navigate("/login");
    }
    /**
     * 判断路由权限
     * */
    const matchRoutesItem = matchRoutes(routesConfig, location.pathname);
    /**
     * 当前路由存在
     * 且 当前路由没有纯前端可访问权限
     * 且 当前路由没有后端配置的前端权限
     * 所有条件达成，跳转至无权限页面
     */
    if (
      matchRoutesItem &&
      matchRoutesItem[matchRoutesItem.length - 1].route.path !== "*" &&
      !frontPermissionedKey.some((item) => location.pathname.includes(item)) &&
      !(
        userInfo &&
        userInfo.functions.some((item: string) =>
          location.pathname.includes(item)
        )
      )
    ) {
      return navigate("/error403");
    }
  }, [location.pathname, navigate, userInfo]);

  return useRoutes(routesConfig);
}
