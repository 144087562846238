/*
 * @Description: 示例首页
 * @Author: wudifu
 * @Date: 2021-01-10 21:53:06
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 17:05:18
 */

import HomeLayout from "../../components/home-layout";
import HomeHeader from "../../components/home-header/home-header";
import HeaderUser from "../../components/header-user";
import { headerMenuData } from "../../router/config";

import "./home.scss";

const headerTitle = (
  <div className="header-title">
    <div className="logo">
      <div className="logo-text">logo</div>
    </div>
    <div className="title">DEMO</div>
  </div>
);

export default function Home() {
  return (
    <HomeLayout
      header={
        <HomeHeader
          headerTitle={headerTitle}
          headerUser={
            <HeaderUser forgetPasswordOptions={["email"]}></HeaderUser>
          }
          headerMenuData={headerMenuData}
        />
      }
    />
  );
}
