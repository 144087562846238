/*
 * @Description: 路由静态配置
 * @Author: wudifu
 * @Date: 2021-04-28 10:13:19
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 10:35:27
 */

import Login from "../pages/login";
import Error404 from "../pages/error-page/error-404";
import Error403 from "../pages/error-page/error-403";
import Home from "../pages/home/index";
import { DocumentLogin } from "../pages/document";
import TimelineScrolled from "../pages/timeline-scrolled";
import ForecastMultiDay from "../pages/forecast-multi-day";

import { UploadOutlined } from "@ant-design/icons";
import { RouteObject } from "react-router-dom";

/**
 * 路由配置
 */
export const routesConfig: RouteObject[] = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <Home />,
    children: [
      {
        path: "/document-login",
        element: <DocumentLogin />,
      },
      {
        path: "/forecast-multi-day",
        element: <ForecastMultiDay />,
      },
      {
        path: "/timeline-scrolled",
        element: <TimelineScrolled />,
      },
      // 403与404页面请勿删除，否则影响权限判断
      {
        path: "/error403",
        element: <Error403 />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  // 403与404页面请勿删除，否则影响权限判断
  {
    path: "/error403",
    element: <Error403 />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

/**
 * 菜单配置
 */
export const headerMenuData = [
  {
    label: "用户与登录模块文档",
    key: "document-login",
    icon: <UploadOutlined />,
  },
  {
    label: "可滚动时间轴",
    key: "timeline-scrolled",
    icon: <UploadOutlined />,
  },
  {
    label: "7日预报",
    key: "forecast-multi-day",
    icon: <UploadOutlined />,
  },
];

/**
 * 纯前端路由可访问权限，优先级高于登录接口回传的后端配置的前端权限
 */
export const frontPermissionedKey = [
  "login",
  "error403",
  // 上面登录与403权限不可删除
  "document-login",
  "timeline-scrolled",
  "forecast-multi-day",
];
