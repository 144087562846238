/*
 * @Description: 忘记密码组件
 * @Author: wudifu
 * @Date: 2021-01-19 11:49:08
 * @LastEditors: wudifu
 * @LastEditTime: 2021-09-07 15:07:27
 */
import React, { useState, useMemo, ReactNode, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageType } from "../../../../store/auth/slice";
import { Row, Col, Form, Button, Input, Steps, message } from "antd";
import "./forget-password.scss";
import MainStepButton from "./components/main-step-button";
import forgetPhoneImg from "../../assets/images/forgetPhone.png";
import forgetEmailImg from "../../assets/images/forgetEmail.png";
import {
  checkUser,
  getVerificationCode,
  verifyUser,
  resetPassword,
} from "../../../../api/auth/forget-password";
enum propsToApiKey {
  "cellphone" = "phone",
  "email" = "mailbox",
}
export default function ForgetPassword(props: {
  title: string;
  logo?: ReactNode;
  forgetPasswordOptions?: ("email" | "cellphone")[];
}) {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [step1Loading, setStep1Loading] = useState(false);
  const [step2Loading, setStep2Loading] = useState(false);
  const [step3Loading, setStep3Loading] = useState(false);
  const [verificationType, setVerificationType] = useState<
    "cellphone" | "email"
  >(() => {
    if (props.forgetPasswordOptions) {
      if (props.forgetPasswordOptions.length === 1) {
        return props.forgetPasswordOptions[0];
      } else {
        return "cellphone";
      }
    } else {
      return "cellphone";
    }
  });
  const verificationTitle = useMemo(() => {
    return {
      cellphone: "手机",
      email: "邮箱",
    }[verificationType];
  }, [verificationType]);
  const [verificationData, setVerificationData] = useState<{
    username: string;
    phone: string;
    mailbox: string;
  } | null>(null);
  const selectedVerificationData = useMemo(() => {
    return verificationData
      ? verificationData[propsToApiKey[verificationType]]
      : "";
  }, [verificationType, verificationData]);
  const verificationDisabledInterval = useRef<number>();
  const [verificationDisabledTime, setVerificationDisabledTime] = useState(60);
  const [verificationDisabled, setVerificationDisabled] = useState(false);
  useEffect(() => {
    if (verificationDisabled) {
      verificationDisabledInterval.current = window.setInterval(() => {
        if (verificationDisabledTime > 0) {
          setVerificationDisabledTime(verificationDisabledTime - 1);
        }
      }, 1000);
    } else {
      setVerificationDisabledTime(60);
    }
    return () => {
      window.clearInterval(verificationDisabledInterval.current);
    };
  }, [verificationDisabled, verificationDisabledTime]);
  useEffect(() => {
    if (currentStep !== 1 || verificationDisabledTime === 0) {
      if (verificationDisabledInterval) {
        window.clearInterval(verificationDisabledInterval.current);
        setVerificationDisabled(false);
      }
    }
  }, [currentStep, verificationDisabledTime]);
  const onStep1FormFinished = async (form: { info: string }) => {
    try {
      const info: any = await checkUser(form.info);
      console.log(info);
      setVerificationData(info);
      setCurrentStep(1);
    } catch (err: any) {
      console.error(err);
      message.error(err.data);
    } finally {
      setStep1Loading(false);
    }
  };
  const onStep2FormFinished = async (form: { code: string }) => {
    setStep2Loading(true);
    try {
      const status = await verifyUser(form.code);
      console.log(status);
      setCurrentStep(2);
    } catch (err: any) {
      console.error(err);
      message.error(err.data);
    } finally {
      setStep2Loading(false);
    }
  };
  const onStep3FormFinished = async (form: { password: string }) => {
    setStep3Loading(true);
    try {
      const status = await resetPassword(form.password);
      console.log(status);
      setCurrentStep(3);
    } catch (err: any) {
      console.error(err);
      message.error(err.data);
    } finally {
      setStep3Loading(false);
    }
  };
  return (
    <div className="components-forget-password">
      <div className="components-forget-password__header">
        {props.logo || (
          <div className="components-forget-password__header__logo">
            <span className="components-forget-password__header__logo__text">
              logo
            </span>
          </div>
        )}
        <div className="components-forget-password__header__title">
          {props.title}
        </div>
        <div className="components-forget-password__header__divider" />
        <div className="components-forget-password__header__sub-title">
          找回密码
        </div>
      </div>
      <div className="components-forget-password__main">
        <div className="components-forget-password__main__steps">
          <Steps
            current={currentStep}
            labelPlacement="vertical"
            items={[
              {
                title: "填写账号",
              },
              {
                title: "身份验证",
              },
              {
                title: "设置新密码",
              },
              {
                title: "完成",
              },
            ]}
          ></Steps>
        </div>
        <div className="components-forget-password__main__content">
          {currentStep === 0 && (
            <div className="components-forget-password__main__step1">
              <div className="components-forget-password__main__step1__header">
                请填写需要找回的账号
              </div>
              <Form name="step1" size="large" onFinish={onStep1FormFinished}>
                <Form.Item
                  name="info"
                  rules={[
                    { required: true, message: "请输入用户名/手机号/邮箱！" },
                  ]}
                >
                  <Input placeholder="请输入用户名/手机号/邮箱" />
                </Form.Item>
                <MainStepButton loading={step1Loading} />
              </Form>
            </div>
          )}
          {currentStep === 1 && (
            <div className="components-forget-password__main__step2">
              <div className="components-forget-password__main__step2__button">
                {(!props.forgetPasswordOptions ||
                  props.forgetPasswordOptions.length === 0 ||
                  props.forgetPasswordOptions.findIndex((item) => {
                    return item === "cellphone";
                  }) !== -1) && (
                  <div
                    className={`components-forget-password__main__step2__verification-type ${
                      verificationType === "cellphone"
                        ? "components-forget-password__main__step2__verification-type--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setVerificationType("cellphone");
                    }}
                  >
                    手机验证
                  </div>
                )}
                {(!props.forgetPasswordOptions ||
                  props.forgetPasswordOptions.length === 0 ||
                  props.forgetPasswordOptions.findIndex((item) => {
                    return item === "email";
                  }) !== -1) && (
                  <div
                    className={`components-forget-password__main__step2__verification-type ${
                      verificationType === "email"
                        ? "components-forget-password__main__step2__verification-type--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setVerificationType("email");
                    }}
                  >
                    邮箱验证
                  </div>
                )}
              </div>
              <Form name="step2" size="large" onFinish={onStep2FormFinished}>
                <Row gutter={20}>
                  <Col flex="auto">
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: "请输入验证码！" },
                        { len: 6, message: "验证码为6位数" },
                      ]}
                    >
                      <Input placeholder="请输入6位数验证码" />
                    </Form.Item>
                  </Col>
                  <Col flex="1">
                    <Button
                      className="components-forget-password__main__step2__verification-code-button"
                      disabled={verificationDisabled}
                      onClick={() => {
                        getVerificationCode(
                          { cellphone: "1", email: "2" }[
                            verificationType
                          ] as any
                        )
                          .then((res) => {
                            console.log(res);
                            message.success("验证码已发送");
                            setVerificationDisabled(true);
                          })
                          .catch((err) => console.error(err));
                      }}
                    >
                      获取验证码
                    </Button>
                  </Col>
                  <Col flex="1">
                    <div className="components-forget-password__main__step2__verification-code-disabled-text">
                      {verificationDisabled && `${verificationDisabledTime}s`}
                    </div>
                  </Col>
                </Row>
                <div className="components-forget-password__main__step2__text">
                  点击获取验证码，验证码将发送至{verificationTitle}{" "}
                  {selectedVerificationData}
                </div>
                <MainStepButton loading={step2Loading} />
              </Form>
            </div>
          )}
          {currentStep === 2 && (
            <div className="components-forget-password__main__step3">
              <div className="components-forget-password__main__step3__header">
                请设置新的账号密码
              </div>
              <Form
                name="step1"
                size="large"
                labelCol={{ span: 5 }}
                onFinish={onStep3FormFinished}
              >
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "请输入新密码！" },
                    { min: 6, message: "密码不得少于6位！" },
                  ]}
                >
                  <Input type="password" placeholder="请输入新密码" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  rules={[
                    { required: true, message: "请再次输入新密码！" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入的密码不一致");
                      },
                    }),
                  ]}
                >
                  <Input type="password" placeholder="请再次输入新密码" />
                </Form.Item>
                <MainStepButton loading={step3Loading} />
              </Form>
            </div>
          )}
          {currentStep === 3 && (
            <div className="components-forget-password__main__step4">
              <div className="components-forget-password__main__step4__header">
                <div className="components-forget-password__main__step4__header__img">
                  <img
                    src={
                      verificationType === "cellphone"
                        ? forgetPhoneImg
                        : forgetEmailImg
                    }
                    alt=""
                  />
                </div>
                <div className="components-forget-password__main__step4__header__text">
                  账号{selectedVerificationData}重置密码成功
                </div>
              </div>
              <Button
                className="components-forget-password__main__step4__to-login"
                size="large"
                type="primary"
                onClick={() => {
                  dispatch(setPageType("login"));
                }}
              >
                立即登录
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
