/*
 * @Description: 7日预报组件
 * @Author: wudifu
 * @Date: 2021-04-26 10:34:04
 * @LastEditors: wudifu
 * @LastEditTime: 2021-05-07 14:59:53
 */
import React, { ReactNode, useRef, useEffect } from "react";
import dayjs from "dayjs";

import { ArrowUpOutlined } from "@ant-design/icons";
import "./forecast-multi-day.scss";
import bingbao from "./assets/images/bingbao.png";
import dawu from "./assets/images/dawu.png";
import daxue from "./assets/images/daxue.png";
import dayu from "./assets/images/dayu.png";
import duoyun from "./assets/images/duoyun.png";
import leizhenyu from "./assets/images/leizhenyu.png";
import longjuanfeng from "./assets/images/longjuanfeng.png";
import qing from "./assets/images/qing.png";
import shachenbao from "./assets/images/shachenbao.png";
import wanshangduoyun from "./assets/images/wanshangduoyun.png";
import wanshangqing from "./assets/images/wanshangqing.png";
import wumai from "./assets/images/wumai.png";
import xiaoxue from "./assets/images/xiaoxue.png";
import xiaoyu from "./assets/images/xiaoyu.png";
import yangsha from "./assets/images/yangsha.png";
import yin from "./assets/images/yin.png";
import yujiaxue from "./assets/images/yujiaxue.png";
import zhongxue from "./assets/images/zhongxue.png";
import zhongyu from "./assets/images/zhongyu.png";

import * as echarts from "echarts/core";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { GridComponent, GridComponentOption } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
type ECOption = echarts.ComposeOption<LineSeriesOption | GridComponentOption>;
echarts.use([GridComponent, LineChart, CanvasRenderer]);

export type WeatherKey =
  | "bingbao"
  | "dawu"
  | "daxue"
  | "dayu"
  | "duoyun"
  | "leizhenyu"
  | "longjuanfeng"
  | "qing"
  | "shachenbao"
  | "wanshangduoyun"
  | "wanshangqing"
  | "wumai"
  | "xiaoxue"
  | "xiaoyu"
  | "yangsha"
  | "yin"
  | "yujiaxue"
  | "zhongxue"
  | "zhongyu";
export enum WeatherKeyToLabel {
  "bingbao" = "冰雹",
  "dawu" = "大雾",
  "daxue" = "大雪",
  "dayu" = "大雨",
  "duoyun" = "多云",
  "leizhenyu" = "雷阵雨",
  "longjuanfeng" = "龙卷风",
  "qing" = "晴",
  "shachenbao" = "沙尘暴",
  "wanshangduoyun" = "夜间多云",
  "wanshangqing" = "夜间晴",
  "wumai" = "雾霾",
  "xiaoxue" = "小雪",
  "xiaoyu" = "小雨",
  "yangsha" = "扬沙",
  "yin" = "阴",
  "yujiaxue" = "雨夹雪",
  "zhongxue" = "中雪",
  "zhongyu" = "中雨",
}
export const WeatherKeyToImg = {
  bingbao,
  dawu,
  daxue,
  dayu,
  duoyun,
  leizhenyu,
  longjuanfeng,
  qing,
  shachenbao,
  wanshangduoyun,
  wanshangqing,
  wumai,
  xiaoxue,
  xiaoyu,
  yangsha,
  yin,
  yujiaxue,
  zhongxue,
  zhongyu,
};
const IndexToDaysOfWeek = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
];
export const windLevelCriterion: {
  name: string;
  landDescription: string;
  waveDescription: string;
  waveHeight: number;
  windSpeed: [number, number];
  windLevel: number;
}[] = [
  {
    name: "无风",
    landDescription: "烟直上",
    waveDescription: "平静",
    waveHeight: 0,
    windSpeed: [0, 0.2],
    windLevel: 0,
  },
  {
    name: "软风",
    landDescription: "烟示风向",
    waveDescription: "微波峰无飞沫",
    waveHeight: 0.1,
    windSpeed: [0.3, 1.5],
    windLevel: 1,
  },
  {
    name: "轻风",
    landDescription: "感觉有风",
    waveDescription: "小波峰未破碎",
    waveHeight: 0.6,
    windSpeed: [1.6, 3.3],
    windLevel: 2,
  },
  {
    name: "微风",
    landDescription: "旌旗展开",
    waveDescription: "小波峰顶破裂",
    waveHeight: 0.6,
    windSpeed: [3.4, 5.4],
    windLevel: 3,
  },
  {
    name: "和风",
    landDescription: "吹起尘土",
    waveDescription: "小浪白沫波峰",
    waveHeight: 1,
    windSpeed: [5.5, 7.9],
    windLevel: 4,
  },
  {
    name: "劲风",
    landDescription: "小树摇摆",
    waveDescription: "中浪折沫峰群",
    waveHeight: 2,
    windSpeed: [8, 10.7],
    windLevel: 5,
  },
  {
    name: "强风",
    landDescription: "电线有声",
    waveDescription: "大浪到个飞沫",
    waveHeight: 3,
    windSpeed: [10.8, 13.8],
    windLevel: 6,
  },
  {
    name: "疾风",
    landDescription: "步行困难",
    waveDescription: "破峰白沫成条",
    waveHeight: 4,
    windSpeed: [13.9, 17.1],
    windLevel: 7,
  },
  {
    name: "大风",
    landDescription: "折毁树枝",
    waveDescription: "浪长高有浪花",
    waveHeight: 5.5,
    windSpeed: [17.2, 20.7],
    windLevel: 8,
  },
  {
    name: "烈风",
    landDescription: "小损房屋",
    waveDescription: "浪峰倒卷",
    waveHeight: 7,
    windSpeed: [20.8, 24.4],
    windLevel: 9,
  },
  {
    name: "狂风",
    landDescription: "拔起树木",
    waveDescription: "海浪翻滚咆哮",
    waveHeight: 9,
    windSpeed: [24.5, 28.4],
    windLevel: 10,
  },
  {
    name: "暴风",
    landDescription: "损毁普遍",
    waveDescription: "波峰全呈飞沫",
    waveHeight: 11.5,
    windSpeed: [28.5, 32.6],
    windLevel: 11,
  },
  {
    name: "飓风",
    landDescription: "摧毁巨大",
    waveDescription: "海浪滔天",
    waveHeight: 14,
    windSpeed: [32.7, Infinity],
    windLevel: 0,
  },
];

export const generateWindLevelBySpeed = (
  speed: number,
  criterion: {
    name: string;
    landDescription: string;
    waveDescription: string;
    waveHeight: number;
    windSpeed: [number, number];
    windLevel: number;
  }[]
) => {
  return criterion.find(
    (item) => speed >= item.windSpeed[0] && speed <= item.windSpeed[1]
  );
};

export const windDirectionCriterion: {
  name: string;
  symbol: string;
  center: number;
  range: [number, number];
}[] = [
  {
    name: "北",
    symbol: "N",
    center: 0,
    range: [348.76, 11.25],
  },
  {
    name: "北东北",
    symbol: "NNE",
    center: 22.5,
    range: [11.26, 33.75],
  },
  {
    name: "东北",
    symbol: "NE",
    center: 45,
    range: [33.76, 56.25],
  },
  {
    name: "东东北",
    symbol: "ENE",
    center: 67.5,
    range: [56.26, 78.75],
  },
  {
    name: "东",
    symbol: "E",
    center: 90,
    range: [78.76, 101.25],
  },
  {
    name: "东东南",
    symbol: "ESE",
    center: 112.5,
    range: [101.26, 123.75],
  },
  {
    name: "东南",
    symbol: "SE",
    center: 135,
    range: [123.76, 146.25],
  },
  {
    name: "南东南",
    symbol: "SSE",
    center: 157.5,
    range: [146.26, 168.75],
  },
  {
    name: "南",
    symbol: "S",
    center: 180,
    range: [168.76, 191.25],
  },
  {
    name: "南西南",
    symbol: "SSW",
    center: 202.5,
    range: [191.26, 213.75],
  },
  {
    name: "西南",
    symbol: "SW",
    center: 225,
    range: [213.76, 236.25],
  },
  {
    name: "西西南",
    symbol: "WSW",
    center: 247.5,
    range: [236.26, 258.75],
  },
  {
    name: "西",
    symbol: "W",
    center: 270,
    range: [258.76, 281.25],
  },
  {
    name: "西西北",
    symbol: "WNW",
    center: 295.5,
    range: [281.26, 303.75],
  },
  {
    name: "西北",
    symbol: "NW",
    center: 315,
    range: [303.76, 326.25],
  },
  {
    name: "北西北",
    symbol: "NNW",
    center: 337.5,
    range: [326.26, 348.75],
  },
];

export const generateWindDirectionByDegree = (
  deg: number,
  criterion: {
    name: string;
    symbol: string;
    center: number;
    range: [number, number];
  }[]
) => {
  return criterion.find((item) => deg >= item.range[0] && deg <= item.range[1]);
};

export default function Forecast7Days(props: {
  data: {
    dayWeather: WeatherKey;
    nightWeather: WeatherKey;
    temperature: [number, number];
    windDirection: number;
    windSpeed: number;
  }[];
  windImg?: ReactNode;
  stripe?: boolean;
  echartsColor?: string[];
  windDirectionType?: "image" | "text";
  windSpeedType?: "speed" | "level";
}) {
  const echartsDOMRef = useRef<HTMLDivElement>(null);
  const echartsInstanceRef = useRef<echarts.ECharts | null>(null);
  const resizeObserver = useRef(
    new ResizeObserver(() => {
      if (echartsInstanceRef.current) echartsInstanceRef.current.resize();
    })
  );
  useEffect(() => {
    if (echartsDOMRef.current && !echartsInstanceRef.current) {
      echartsInstanceRef.current = echarts.init(echartsDOMRef.current);
      resizeObserver.current.observe(echartsDOMRef.current);
    }
  }, []);
  useEffect(() => {
    if (echartsInstanceRef.current) {
      const options: ECOption = {
        color: props.echartsColor || [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        grid: {
          top: 32,
          bottom: 20,
          left: 0,
          right: 0,
        },
        xAxis: {
          show: false,
          type: "category",
        },
        yAxis: {
          show: false,
          type: "value",
          min: "dataMin",
          max: "dataMax",
        },
        series: [
          {
            name: "最高气温",
            type: "line",
            symbol: "circle",
            symbolSize: 6,
            stack: "气温",
            smooth: true,
            data: props.data.map((item) => item.temperature[0]),
            label: {
              show: true,
              position: "bottom",
              fontSize: 14,
              formatter: "{c}℃",
            },
          },
          {
            name: "最低气温",
            type: "line",
            symbol: "circle",
            symbolSize: 6,
            stack: "气温",
            smooth: true,
            data: props.data.map((item) => item.temperature[1]),
            label: {
              show: true,
              fontSize: 14,
              formatter: "{c}℃",
            },
          },
        ],
      };
      echartsInstanceRef.current.setOption(options);
    }
  }, [props.data, props.echartsColor]);

  return (
    <div className="components-forecast-multi-day">
      {props.data.map((item, index) => {
        const todayMillseconds =
          dayjs().valueOf() + index * 1000 * 60 * 60 * 24;
        return (
          <React.Fragment key={index}>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__date">
                {index === 0
                  ? "今天"
                  : index === 1
                  ? "明天"
                  : index === 2
                  ? "后天"
                  : `${dayjs(todayMillseconds).date()}日`}
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__day">
                {IndexToDaysOfWeek[dayjs(todayMillseconds).day()]}
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__weather-icon">
                <img
                  src={WeatherKeyToImg[item.dayWeather]}
                  alt={item.dayWeather}
                />
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__weather-text">
                {WeatherKeyToLabel[item.dayWeather]}
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__weather-icon">
                <img
                  src={WeatherKeyToImg[item.nightWeather]}
                  alt={item.nightWeather}
                />
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              <div className="components-forecast-multi-day__one-day-item__weather-text">
                {WeatherKeyToLabel[item.nightWeather]}
              </div>
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              {props.windDirectionType === "text" ? (
                <div className="components-forecast-multi-day__one-day-item__wind-direction-text">
                  {generateWindDirectionByDegree(
                    item.windDirection,
                    windDirectionCriterion
                  )?.name ?generateWindDirectionByDegree(
                    item.windDirection,
                    windDirectionCriterion
                  )?.name + '风' :  "暂无风向"}
                </div>
              ) : (
                <div
                  className="components-forecast-multi-day__one-day-item__wind-direction"
                  style={{ transform: `rotate(${item.windDirection}deg)` }}
                >
                  {props.windImg || <ArrowUpOutlined />}
                </div>
              )}
            </div>
            <div
              className={`components-forecast-multi-day__one-day-item${
                props.stripe && index % 2 === 0
                  ? " components-forecast-multi-day__one-day-item--stripe"
                  : ""
              }`}
            >
              {props.windSpeedType === "level" ? (
                <div className="components-forecast-multi-day__one-day-item__wind-level">
                  {generateWindLevelBySpeed(item.windSpeed, windLevelCriterion)
                    ?.windLevel || "暂无风力等"}
                  级
                </div>
              ) : (
                <div className="components-forecast-multi-day__one-day-item__wind-speed">
                  {item.windSpeed}m/s
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
      <div
        className="components-forecast-multi-day__echarts"
        style={{ gridColumnEnd: props.data.length + 1 }}
      >
        <div
          className="components-forecast-multi-day__echarts__dom"
          ref={echartsDOMRef}
        ></div>
        {props.data.map((item, index) => (
          <div
            key={index}
            className={`components-forecast-multi-day__one-day-item${
              props.stripe && index % 2 === 0
                ? " components-forecast-multi-day__one-day-item--stripe"
                : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}
