/*
 * @Description: redux store
 * @Author: wudifu
 * @Date: 2021-01-07 17:51:42
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-13 13:38:26
 */
import rootReducers from "./reducers";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({ reducer: rootReducers });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
