/*
 * @Description: 登录表单组件
 * @Author: wudifu
 * @Date: 2021-01-07 17:47:39
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 22:03:17
 */
import React from "react";
import { useSelector } from "react-redux";
import { loginAsync, setPageType } from "../../../../store/auth/slice";
import { loginLoadingSelector } from "../../../../store/auth/selector";

import { Form, Input, Button, Checkbox } from "antd";

import Captcha from "../captcha";

import "./login-form.scss";

import usernameImg from "../../assets/images/username.png";
import passwordImg from "../../assets/images/password.png";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppDispatch } from "../../../../hooks";

export default function Login(props: {
  className?: string;
  hasRememberMe?: boolean;
  hasRegister?: boolean;
  hasForgetPassword?: boolean;
}) {
  const dispatch = useAppDispatch();
  const loginLoading: boolean = useSelector(loginLoadingSelector);

  const onFormFinished = async (loginForm: {
    username: string;
    password: string;
  }) => {
    dispatch(loginAsync(loginForm));
  };
  const rememberMeJSON = localStorage.getItem("rememberMe");
  const rememberMe = rememberMeJSON ? JSON.parse(rememberMeJSON) : false;
  const onRememberMeChange = (event: CheckboxChangeEvent) => {
    localStorage.setItem("rememberMe", JSON.stringify(event.target.checked));
  };
  return (
    <div className={props.className || "components-login-form-dark"}>
      <Form
        name="login"
        className="components-login-form__form"
        size="large"
        initialValues={{ rememberMe }}
        onFinish={onFormFinished}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "请输入用户名/手机号/邮箱！" }]}
        >
          <Input
            prefix={<img src={usernameImg} alt="username-img" />}
            placeholder="请输入用户名/手机号/邮箱"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "请输入登录密码！" },
            { min: 6, message: "密码不得少于6位" },
          ]}
        >
          <Input.Password
            prefix={<img src={passwordImg} alt="password-img" />}
            placeholder="请输入登录密码"
          />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: "请输入验证码！",
            },
          ]}
        >
          <Input addonAfter={<Captcha></Captcha>} placeholder="请输入验证码" />
        </Form.Item>
        {props.hasRememberMe === true && (
          <Form.Item name="rememberMe" valuePropName="checked">
            <Checkbox onChange={onRememberMeChange} checked={rememberMe}>
              <span className="components-login-form__form__checkbox-label">
                记住登录状态
              </span>
            </Checkbox>
          </Form.Item>
        )}
        <Button type="primary" htmlType="submit" loading={loginLoading} block>
          登录
        </Button>
      </Form>
      <div className="components-login-form__button-row">
        {props.hasForgetPassword === true && (
          <Button
            className="components-login-form__button-row__text-button"
            type="text"
            onClick={() => {
              dispatch(setPageType("forget"));
            }}
          >
            忘记密码?
          </Button>
        )}
        {props.hasRegister === true && (
          <Button
            className="components-login-form__button-row__text-button"
            type="text"
            onClick={() => {
              dispatch(setPageType("register"));
            }}
          >
            立即注册
          </Button>
        )}
      </div>
    </div>
  );
}
