/*
 * @Description: 登录页
 * @Author: wudifu
 * @Date: 2021-04-28 11:01:56
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-28 11:03:27
 */
import Login from "../../components/login";

export default function LoginPage() {
  return (
    <Login
      title="DEMO（表单任意填写）"
      theme="dark"
      type="modal"
      loginFormOptions={{
        hasRegister: true,
        hasForgetPassword: true,
        hasRememberMe: true,
      }}
      forgetPasswordOptions={["cellphone", "email"]}
    />
  );
}
