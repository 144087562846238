/*
 * @Description: 多日天气页面
 * @Author: wudifu
 * @Date: 2021-04-28 11:05:23
 * @LastEditors: wudifu
 * @LastEditTime: 2021-05-07 15:16:39
 */
import { Card } from "antd";
import ForecastMultiDay from "../../components/forecast-multi-day";

export default function ForecastMultiDayPage() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        overflow: "auto",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          minWidth: "65vw",
          maxWidth: "80vw",
          margin: "36px",
        }}
      >
        <ForecastMultiDay
          stripe
          windDirectionType="image"
          windSpeedType="speed"
          echartsColor={["#00a4fe", "#30c8ac"]}
          data={[
            {
              dayWeather: "qing",
              nightWeather: "wanshangqing",
              temperature: [10, 20],
              windDirection: 30,
              windSpeed: 4,
            },
            {
              dayWeather: "yin",
              nightWeather: "wanshangduoyun",
              temperature: [13, 19],
              windDirection: 50,
              windSpeed: 6,
            },
            {
              dayWeather: "xiaoyu",
              nightWeather: "zhongyu",
              temperature: [8, 14],
              windDirection: 80,
              windSpeed: 9,
            },
            {
              dayWeather: "yin",
              nightWeather: "yin",
              temperature: [11, 16],
              windDirection: 200,
              windSpeed: 5,
            },
            {
              dayWeather: "qing",
              nightWeather: "qing",
              temperature: [15, 24],
              windDirection: 300,
              windSpeed: 2,
            },
            {
              dayWeather: "qing",
              nightWeather: "wanshangqing",
              temperature: [15, 27],
              windDirection: 20,
              windSpeed: 2,
            },
            {
              dayWeather: "duoyun",
              nightWeather: "wanshangduoyun",
              temperature: [10, 28],
              windDirection: 180,
              windSpeed: 5,
            },
          ]}
        />
      </Card>
      <zero-md
        style={{
          margin: "36px",
        }}
        src={process.env.PUBLIC_URL + "/READMEFORECASTMULTIDAY.md"}
      ></zero-md>
    </div>
  );
}
