/*
 * @Description: 顶栏组件
 * @Author: wudifu
 * @Date: 2021-04-29 16:24:22
 * @LastEditors: wudifu
 * @LastEditTime: 2021-06-22 17:01:54
 */
import { ReactNode, useEffect, useMemo } from "react";
import { useNavigate, useMatch } from "react-router-dom";

import { Menu } from "antd";

import { frontPermissionedKey } from "../../router/config";

import { useSelector } from "react-redux";
import { userInfoSelector } from "../../store/auth/selector";

import "./home-header.scss";

interface HomeHeaderProps {
  headerTitle: ReactNode;
  headerMenuData: {
    label: string;
    key: string;
    icon: ReactNode;
    visible?: boolean;
  }[];
  headerUser: ReactNode;
}

export default function HomeHeader(props: HomeHeaderProps) {
  const navigate = useNavigate();
  const match = useMatch("/:header");

  const userInfo = useSelector(userInfoSelector);

  const authorizedMenuData = useMemo(() => {
    return props.headerMenuData.filter((item) => {
      // 若菜单存在于纯前端权限中，则认为有权限
      if (frontPermissionedKey.some((permitKey) => permitKey === item.key))
        return true;
      // 否则判断菜单是否存在于后端回传的前端权限中，存在则认为有权限
      else
        return (
          userInfo.functions.findIndex(
            (permissionKey: string) => permissionKey === item.key
          ) !== -1
        );
    });
  }, [props.headerMenuData, userInfo]);

  useEffect(() => {
    if (!match?.params.header) {
      navigate(`/${authorizedMenuData[0].key}`);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="components-home-header">
      <div className="components-home-header__main">
        {props.headerTitle}
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[`${match?.params?.header}`]}
          onSelect={(selectedItem) => {
            navigate(`/${selectedItem.key}`);
          }}
          items={authorizedMenuData.filter((item) => item.visible !== false)}
        ></Menu>
      </div>
      {props.headerUser}
    </div>
  );
}
