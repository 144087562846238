/*
 * @Description: 分类可滚动按钮组
 * @Author: wudifu
 * @Date: 2021-12-06 14:56:02
 * @LastEditors: wudifu
 * @LastEditTime: 2021-12-06 16:07:03
 */
import { useEffect, useRef } from "react";
import BetterScroll from "better-scroll";
import { TimelineDataType } from "../utils";

import "./buttons-typed-scrolled.scss";

export default function ButtonsTypedScrolled(props: {
  data: TimelineDataType;
  selectedButton: string;
  onSelectedButtonChange: (selectedButton: string) => void;
}) {
  const buttonsTypedScrolledDomRef = useRef<HTMLDivElement>(null);
  const buttonsTypedScrolledInstance = useRef<BetterScroll | null>(null);
  // 初始加载生成betterScroll实例
  useEffect(() => {
    if (buttonsTypedScrolledDomRef.current) {
      buttonsTypedScrolledInstance.current = new BetterScroll(
        buttonsTypedScrolledDomRef.current,
        {
          scrollX: true,
          probeType: 3,
          mouseWheel: true,
        }
      );
    }
  }, []);
  // 数据变化时，刷新betterScroll实例
  useEffect(() => {
    buttonsTypedScrolledInstance.current?.refresh();
  }, [props.data]);
  // 监听选中按钮变化时，若按钮不在可视区域内，则自动滚动至可视区域中心
  useEffect(() => {
    const currentElement: HTMLDivElement | null = document.querySelector(
      `.button-wrapper__scroll-anchor-${props.selectedButton}`
    );
    if (currentElement && buttonsTypedScrolledDomRef.current) {
      const currentElementBoundingRect = currentElement.getBoundingClientRect();
      const buttonsTypedScrolledBoundingRect =
        buttonsTypedScrolledDomRef.current.getBoundingClientRect();
      // 判断选中元素右边界是否在外层包裹元素右边界之外 或 选中元素左边界在外层包裹元素左边界之外，若是则滚动选中元素至中心位置
      if (
        currentElementBoundingRect.right >
          buttonsTypedScrolledBoundingRect.right ||
        currentElementBoundingRect.left < buttonsTypedScrolledBoundingRect.left
      ) {
        buttonsTypedScrolledInstance.current?.scrollToElement(
          currentElement,
          200,
          true,
          true
        );
      }
    }
  }, [props.selectedButton]);
  return (
    <div
      className="components-buttons-typed-scrolled"
      ref={buttonsTypedScrolledDomRef}
    >
      {/* 使用button-typed-scroll__inner是因为better-scroll库横向滚动要求外层为white-space:nowrap;本层为display: inline-block; */}
      <div className="components-buttons-typed-scrolled__inner">
        {props.data.map((typeItem) => {
          return (
            <div
              className="components-buttons-typed-scrolled__inner__type-wrapper"
              key={typeItem.type}
            >
              {/* 同样由于上层的inner使用inline-block，想要使本层横向溢出滚动，也需要多套一层inner */}
              <div className="type-wrapper__inner">
                {typeItem.type && (
                  <div className="type-wrapper__inner__type">
                    {typeItem.type}
                  </div>
                )}
                <div className="type-wrapper__inner__button-wrapper">
                  {typeItem.data.map((button) => (
                    <div
                      className={`button-wrapper__button button-wrapper__scroll-anchor-${
                        button.value
                      } ${
                        props.selectedButton === button.value
                          ? "button-wrapper__button--selected"
                          : ""
                      }`}
                      key={button.value}
                      onClick={() => {
                        props.onSelectedButtonChange(button.value);
                      }}
                    >
                      {button.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
