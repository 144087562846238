/*
 * @Description: 首页布局
 * @Author: wudifu
 * @Date: 2021-04-28 15:36:45
 * @LastEditors: wudifu
 * @LastEditTime: 2021-04-29 17:00:21
 */
import { ReactNode } from "react";

import { Layout } from "antd";

import "./home-layout.scss";
import { Outlet } from "react-router-dom";

const { Header } = Layout;

interface HomeLayoutProps {
  header: ReactNode;
}

export default function HomeLayout(props: HomeLayoutProps) {
  return (
    <Layout className="components-layout">
      <Header className="components-layout__header">{props.header}</Header>
      <Layout>
        <Outlet></Outlet>
      </Layout>
    </Layout>
  );
}
